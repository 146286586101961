.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  padding: 15px;

  background: #00000033;
  backdrop-filter: blur(4px);
  border: 1px solid #3D3D3D;
  border-radius: 10px;

  &__title {
    max-width: 214px;
    color: #72BF44;
    opacity: 1;

    font-size: 46px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0px;
    text-align: left;
  }

  &__desc {
    max-width: 195px;
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: 0;
    color: var(--white);

    .desc1 {
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: left;
    }

    .desc2 {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

.cards {
  width: 100%;
  display: flex;
  gap: 15px;

  .card {
    @extend .card;
  }
}

.cards_slider {
  max-width: 400px;

  .card {
    @extend .card;

    max-width: 343px;
    min-height: 376px;
    text-align: start;

    &__img {
      margin-bottom: 26px;
    }
    &__title {
      margin-bottom: 10px;
    }
    &__desc {
      p {
        &:last-child {
          margin-top: 30px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .cards {
    .card {
      &__img {
        width: 30px;
        height: 28px;
        margin-bottom: 16px;
      }
      &__title {
        font-size: 1.6vw;
        line-height: 1.6vw;
      }
      &__desc {
        font-size: 1.4vw;
        line-height: 1.4vw;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .cards_slider {
    max-width: 400px;
    margin-top: 20px;

    .card {
      max-width: 330px;
      min-height: 220px;
      overflow: auto;
      gap: 15px;
      margin-left: 15px;

      &__title {
        max-width: 188.64px;
        margin-bottom: 10px;
      }
      &__desc {
        max-width: 195px;

        p {
          &:last-child {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 655px) {
  .cards_slider {
    .card {
      &__title {
        font-size: 46px;
        line-height: 55px;
        letter-spacing: 0;
      }
      &__desc1 {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0;
      }
      &__desc2 {
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}
@media (max-width: 400px) {
  .cards_slider {
    max-width: 300px;

    .card {
      max-width: 250px;
      min-height: 268px;
      padding: 15px 15px 0;

      &__title {
        max-width: 278px;
      }

      &__desc {
        max-width: 278px;
      }
    }
  }
}