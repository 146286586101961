.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

@media (max-width: 1440px) {
  .home {
    //padding: 0 30px;
  }
}
@media (max-width: 992px) {
  .home {
    //padding: 0 15px;
  }
}
@media (max-width: 767.9px) {
  .home {
    padding-left: 0;
    padding-right: 0;
  }
}