.page {
  width: 100%;
  padding: 64px 0;
  margin-top: 32px;

  background: url('../../../shared/assets/images/hov-invest/BgImg.webp') no-repeat center;
  background-size: cover;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;

    .left_block {
      max-width: 520px;
      .title {
        color: var(--white);
        text-transform: uppercase;

        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        letter-spacing: 0em;
        text-align: left;

        span {
          color: #72BF44;
        }
      }
    }

    .right_block {
      .cards__desktop {
        display: flex;
        flex-wrap: nowrap;
        gap: 24px;

        li {
          min-width: 180px;
          max-width: 214px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 15px;
      padding: 0 30px;
    }
  }
}
@media (max-width: 1280px) {
  .page {
    &__container {
      flex-wrap: wrap;

      .left_block {
        max-width: 100%;

        .title {
          text-align: center;

          font-size: 4vw;
          line-height: 6vw;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    &__container {
      flex-wrap: wrap;
      .left_block {
        max-width: 100%;
        .title {
          font-size: 5vw;
          line-height: 6vw;
        }
      }
    }
  }
}

@media (max-width: 767.9px) {

}
@media (max-width: 655px) {
  .page {
    padding: 30px 0 70px 0;

    &__container {
      .left_block {
        .title {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.02em;
          text-transform: inherit;

          span {
            color: var(--white);
          }
        }
      }
    }
  }
}