.modal {
  &__title {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    color: var(--dark-blue);
  }
  &__form {
    margin-top: 0;

    h3 {
      max-width: calc(100% - 30px);
      margin-right: 15px;

      font-size: 23px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      color: var(--dark-blue);
    }

    button {
      height: 46px !important;
      padding: 12px 18px !important;
    }
  }
}

@media (max-width: 767.9px) {
  .modal {
    &__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }
    &__form {
      h3 {
        margin-right: 15px;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0;
      }
    }
  }
}
@media (max-width: 655px) {
  .modal {
    &__form {
      margin-top: 0;
      padding: 15px;
      overflow: auto;
      max-height: 100%;
    }
  }
}