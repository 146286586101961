.menu {
  ul {
    display: flex;
    flex-direction: row;
    gap: 45px;
  }

  a {
    color: #5F6D7E;

    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
}

@media (max-width: 800px) {
  .menu {
    ul {
      gap: 30px;
    }
  }
}