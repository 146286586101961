.text {
  overflow: auto;
  max-height: 80vh;
  max-width: 80vw;
  margin-top: 50px;
  white-space: pre-wrap;

  font-family: var(--font-family-inter);
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #0F172A;
}