.page {
  width: 100%;
  padding: 30px 0;

  background-image: url("../../../shared/assets/images/about/bg.webp");
  background-color: #323232;

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 64px 0;

    .left_block {
      display: flex;
      flex-wrap: nowrap;
      max-width: 100%;

      &__content {
        width: 100%;
      }

      .title {
        display: block;
        color: var(--white);

        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0px;
        text-align: left;

        span {
          display: inline-block;
          color: #72BF44;
        }
      }
    }

    .center_block {
      .desc {
        max-width: 780px;
        color: var(--white);

        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }
    }

    .right_block {
      height: 100%;
      display: flex;
      align-items: center;

      button {
        font-size: 16px;
        box-sizing: border-box;
        width: 289px;
        height: 60px;
        padding: 18px 27px 18px 27px;
        gap: 10px;
        border-radius: 4px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 15px;
      padding: 30px;

      .left_block {
        min-width: 300px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .page {
    &__container {
      flex-wrap: wrap;
      gap: 30px;

      .left_block {
        display: flex;
        flex-wrap: nowrap;
        max-width: 100%;

        &__content {
          width: 100%;
        }

        .title {
          display: block;
          color: var(--white);

          font-size: 32px;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: 0px;
          text-align: center;

          span {
            display: inline-block;
            color: #72BF44;
          }
        }
      }

      .center_block {
        width: 100%;
        display: flex;
        justify-content: center;

        p {
          text-align: center;
        }
      }

      .right_block {
        height: 55px;
        gap: 0;
        padding-bottom: 20px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      gap: 20px;

      .left_block {
        min-width: 100%;

        .title {
          margin-bottom: 0;

          font-size: 46px;
          line-height: 60px;
          text-align: center;
        }
      }
      .right_block {
        height: auto;
        gap: 0;
        margin-top: 32px;

        .card {
          justify-content: center;

          &__title {
            font-size: 36px;
            line-height: 68px;
          }
          &__desc {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .page {
    padding: 15px 0;

    &__container {
      gap: 16px;

      .left_block {
        .title {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: -0.02em;
          text-align: center;
        }
      }

      .center_block {
        .desc {
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: center;
        }
      }

      .right_block {
        margin-top: 32px;
      }
    }
  }
}