.modal {
  &__title,
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    padding-top: 10px;
    margin-right: 40px;
    text-align: center;
    margin-bottom: 15px;
  }

  &__container {
    position: relative;
  }
  &__close {
    z-index: 9999;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    font-weight: bold;

    color: var(--dark-blue3);

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}

@media (max-width: 655px) {
  .modal {
    &__title {
      margin-bottom: 0;
    }
  }
}