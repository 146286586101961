.page {
  width: 100%;
  padding-top: 40px;

  background-color: var(--white);

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .header {
      &__title {
        max-width: 622px;
        color: #323232;

        font-size: 46px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.02em;
        text-align: center;
      }

      &__desc {
        margin-top: 16px;

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.1px;
        text-align: center;
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;

      .left_block {
        img {
          width: 100%;
          max-width: 558px;
          height: auto;
        }
      }

      .right_block {
        height: 100%;
        padding-top: 30px;

        .card {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: var(--white);
          max-width: 594px;

          &__desc {
            max-width: 575px;
            color: #5F6D7E;

            font-size: 24px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;

            span {
              display: block;
              margin-top: 24px;
              span {
                display: inline;
                color: #72BF44;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 15px;
      padding: 0 30px;
    }
  }
}
@media (max-width: 992px) {
  .page {
    &__container {
      .left_block {
        &__content {
          img {
            min-width: 300px;
          }
        }
      }

      .right_block {
        .card {
          &__title {
            font-size: 4vw;
            line-height: 6vw;
          }
          &__desc {
            font-size: 3vw;
            line-height: 3vw;
         }
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      .content {
        flex-wrap: wrap;

        .right_block {
          height: auto;
          padding-top: 0;
        }
      }
    }
  }
}
@media (max-width: 655px) {
  .page {
    &__container {
      gap: 30px;

      .header {
        &__title {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: -0.02em;
          text-align: center;
        }

        &__desc {
          margin-top: 16px;

          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.1px;
          text-align: center;
        }
      }

      .content {
        .right_block {
          padding-top: 0;

          .card {
            &__desc {
              max-width: 575px;
              color: #5F6D7E;

              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
        }

        .button {
          margin-top: 30px;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .page {
    &__container {
      .content {
        .button {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}