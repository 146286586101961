.page {
  width: 100%;
  padding: 47px 0;

  background: #1A1A1A;
  color: var(--white);

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
  }

  .header {
    max-width: 800px;

    &__title {
      font-size: 46px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.02em;
      text-align: center;
    }

    &__desc {
      padding-top: 16px;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
      text-align: center;
    }
  }

  .content {
    width: 100%;
    display: flex;
    gap: 40px;
    margin-top: 64px;

    .card {
      display: flex;
      box-sizing: border-box;
      height: 417px;
      border-radius: 30px;

      border: 1px solid #414141;
      background: linear-gradient(180deg, #1C5E2E 0%, rgba(31, 49, 36, 0) 102.69%);

      &:first-child {
        width: 100%;
        max-width: 588px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 40px;
        padding: 0 60px;

        p {
          font-size: 22px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: -0.01em;
          text-align: left;
        }
        h4 {
          color: #72BF44;

          font-size: 64px;
          font-weight: 700;
          line-height: 83px;
          letter-spacing: -0.1px;
          text-align: left;
        }
      }

      &:last-child {
        width: 100%;
        max-width: 588px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;

        background-image: url("../../../../shared/assets/images/first-dividends/BgRightImg.webp");
        background-repeat: no-repeat;
        background-size: cover;

        .card__left {
          width: 100%;
          min-width: 270px;
          display: flex;
          flex-direction: column;
          gap: 60px;

          p {
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: -0.01em;
            text-align: left;

            span {
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: -0.1px;
              text-align: left;
            }
          }
        }

        .card__divider {
          width: 1px;
          height: 60%;
          background-color: #FFFFFF;
        }

        .card__right {
          width: 100%;
          padding-left: 32px;

          p {
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: -0.01em;
            text-align: left;
          }

          h4 {
            font-size: 56px;
            font-weight: 700;
            line-height: 73px;
            letter-spacing: -0.1px;
            text-align: left;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    .content {
      .card {
        //width: 100%;

        &:first-child {
         //min-width: 300px;

          max-width: 100%;
        }

        &:last-child {
          //width: 100%;

          max-width: 100%;
        }
      }
    }
  }
}
@media (max-width: 1080px) {
  .page {
    .content {
      gap: 20px;

      .card {
        height: 352px;

        &:first-child {
          padding: 0 30px;
          gap: 10px;

          p {
            font-size: 2vw;
          }

          h4 {
            font-size: 5vw;
          }
        }

        &:last-child {
          flex-wrap: wrap;
          gap: 0;
          padding: 0 30px;

          .card__left {
            gap: 30px;

            p {
              font-size: 2vw;
            }
          }

          .card__divider {
            width: 90%;
            height: 1px;
          }

          .card__right {
            padding-left: 0;

            p {
              font-size: 2vw;
            }

            h4 {
              font-size: 5vw;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    .content {
      .card {
        height: auto;
        gap: 0;

        &:first-child {
          padding: 30px;
          gap: 0;

          p {
            font-size: 2vw;
            line-height: 2vw;
          }

          h4 {
            font-size: 5vw;
            line-height: 8vw;
          }
        }

        &:last-child {
          flex-wrap: wrap;
          gap: 0;
          padding: 30px;

          .card__left {
            gap: 30px;

            p {
              font-size: 2vw;
              line-height: 2vw;
            }
          }

          .card__divider {
            width: 90%;
            height: 1px;
            padding: 0;
            margin: 10px 0;
          }

          .card__right {
            padding-left: 0;

            p {
              font-size: 2vw;
              line-height: 2vw;
            }

            h4 {
              font-size: 5vw;
              line-height: 8vw;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .page {
    .header {
      &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: center;
      }

      &__desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.1px;
        text-align: center;
      }
    }

    .content {
      flex-wrap: wrap;
      gap: 40px;

      .card {
        height: auto;
        gap: 0;

        &:first-child {
          min-height: 222px;
          padding: 30px;
          gap: 0;

          p {
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: -0.01em;
            text-align: left
          }

          h4 {
            font-size: 32px;
            font-weight: 700;
            line-height: 42px;
            letter-spacing: -0.1px;
            text-align: left;
          }
        }

        &:last-child {
          flex-wrap: wrap;
          gap: 0;
          padding: 30px;

          .card__left {
            gap: 30px;

            p {
              font-size: 16px;
              font-weight: 600;
              line-height: 30px;
              letter-spacing: -0.01em;
              text-align: left;
            }
          }

          .card__divider {
            width: 90%;
            height: 1px;
            padding: 0;
            margin: 10px 0;
          }

          .card__right {
            padding-left: 0;

            p {
              font-size: 16px;
              font-weight: 600;
              line-height: 30px;
              letter-spacing: -0.01em;
              text-align: left;
            }

            h4 {
              font-size: 64px;
              font-weight: 700;
              line-height: 83px;
              letter-spacing: -0.1px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}