.footer {
  a {
    color: #5F6D7E;
    &:first-child {
      margin-right: 50px;
    }
  }

  .layout {
    border-top: 1px solid var(--neutral);
    background-color: #F0F0F0;
  }
}

@media (max-width: 767.9px) {
  .footer {
    .layout > div {
      div {
        display: flex;
        flex-direction: column;
        gap: 10px;

        a {
          width: 100%;
          margin: 0;

          font-size: 2vw;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .footer {
    .layout > div {
      div {
        gap: 5px;
        a {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .footer {
    a {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}