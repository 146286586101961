.page {
  width: 100%;
  padding: 64px 0;
  margin-top: -1px;

  background-image: url("../../../shared/assets/images/contacts/BgImg.webp");
  background-repeat: no-repeat;
  background-size: cover;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
    padding: 0 30px;

    .left_block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: var(--white);

      h4 {
        text-transform: uppercase;

        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        letter-spacing: 0px;
        text-align: center;

        span {
          display: block;

          span {
            display: inline-block;
          }
        }
      }
      h3 {
        max-width: 340px;
        padding: 15px 40px;
        margin-top: 56px;

        font-size: 78px;
        font-weight: 800;
        line-height: 78px;
        letter-spacing: 0px;
        text-align: center;

        border-radius: 10px;
        background: #72BF44;
      }
      h5 {
        padding: 10px;
        margin-top: 15px;
        color: #323232;

        font-size: 34px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: center;

        border-radius: 10px;
        background: #EECE42;
      }
    }

    .right_block {
      form {
        width: 513px;
        height: auto;
        padding: 30px 30px 50px 30px;
        border-radius: 10px;
        gap: 15px;

        background: #FFFFFF;

        button {
          height: 46px;
          padding: 12px 18px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 32px;

      .left_block {
        flex: 0.4;
        max-width: 100%;
        padding: 30px;
      }
      .right_block {
        flex: 0.6;

        img {
          min-width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    padding: 32px 0;

    &__container {
      width: 100%;
      gap: 32px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .left_block {
        width: 100%;
        max-width: 549px;
        flex: 1;
      }

      .right_block {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        form {
          height: auto;
          border-radius: 10px;

          background: #FFFFFF;
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      padding: 0;

      .right_block {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        form {
          min-width: calc(100% - 100px);
          height: auto;
          border-radius: 10px;

          background: #FFFFFF;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .page {
    width: 100%;
    padding: 20px 0;

    background: none;

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0;
      padding: 0 20px 30px 20px;

      .left_block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
        padding-bottom: 10px;
        color: var(--white);

        h4 {
          color: #323232;

          font-size: 24px;
          font-weight: 700;
          line-height: 31px;
          letter-spacing: 0px;
          text-align: center;

          span {
            span {
              display: block;
            }
          }
        }

        h3 {
          padding: 5px 40px;
          margin-top: 20px;

          font-size: 40px;
          font-weight: 700;
          line-height: 40px;
          letter-spacing: 0px;
          text-align: center;

          border-radius: 10px;
          background: #72BF44;
        }

        h5 {
          padding: 10px;
          margin-top: 15px;
          color: #323232;

          font-size: 18px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0px;
          text-align: center;

          border-radius: 10px;
          background: #EECE42;
        }

        img {
          margin-top: 20px;
        }
      }

      .right_block {
        form {
          width: 513px;
          height: auto;
          padding: 0;
          border-radius: 10px;

          background: #FFFFFF;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .page {
    &__container {
      .left_block {
        padding: 10px;
      }
    }
  }
}